.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  height: 50px;
  display: flex;
  padding: 10px 10px 25px 25px;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
  background-color: rgb(162, 162, 162);
}

.question {
  max-width: 200px;
  text-align: center;
  margin:0 auto;
}
a:link {
  text-decoration: none;
}

.formfield {
  display: inline-block;
  padding: 10px;
  width:100%;
  margin: 8px 0;
  box-sizing: border-box;
}
.buttonform {
  display: inline-block;
  padding: 10px;
  width:75%;
  margin: 8px 0;
  box-sizing: border-box;

} tr:nth-child(even) {background-color: #f2f2f2;}
th {
  background-color: #222222;
  color: white;
}table {
  width: 100%;
} .check {
  height: 25px;
  width: 25px;
  background-color: #eee;
} .check:hover{
  cursor: pointer;
} td {
  padding:0px;
} .difficulty {
  max-width: 100px;
  text-align: center;
  margin:0 auto;
} .message {
  padding-left: 10px;
}